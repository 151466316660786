





















import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import { ciUserCircle as ciUserCircleRegular } from '~/icons/source/regular/user-circle'
import { ciUserCircle as ciUserCircleSolid } from '~/icons/source/solid/user-circle'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
export default defineComponent({
  setup() {
    const { state, getters } = useNamespacedStore<UserState>(USER_NS)
    const isAnon = getters('isAnon')
    const username = computed(() => state.username)
    const avatar = computed(() => state.avatar)
    const showNotificationsIndicator = computed(() => {
      const unreadCounts = [
        state.unreadMessages,
        state.unreadNotifications,
        state.unreadParkingNotifications,
        state.unreadTradesNotifications,
        state.rogueClassifiedsCount,
        getters('marketplaceSellerNewOrdersCount')
      ]
      return unreadCounts.some(count => count > 0)
    })

    return {
      ciUserCircleRegular,
      ciUserCircleSolid,
      isAnon,
      avatar,
      username,
      showNotificationsIndicator
    }
  }
})
